import React from 'react'

import Layout from '../components/layout'
import Seo from '../components/seo'
import { GetInContact } from "../components/getInContact";

const ImpressumPage = () => (
  <Layout>
    <Seo title="Impressum" />
    <div id="text" className="content-wrapper">
      <div className="content pure-g">
        <div className="l-box pure-u-1-2">
          <h2>Impressum</h2>
          <h3>Simplease OG</h3>
          <p>Frauengasse 7<br/>8010 Graz</p>
          <p><strong>Unternehmensgegenstand:</strong><br/>Werbeagentur</p>
          <p><strong>Firmenbuch-Nummer:</strong><br/>FN348955w</p>
          <p><strong>Firmenbuchgericht:</strong><br/>Landesgericht für ZRS Graz</p>
          <p><strong>Firmensitz:</strong><br/>8010 Graz</p>
          <p><strong>Kontaktdaten:</strong><br/>Tel: +43 316 455065<br/>E-Mail: <a
            href="mailto:office@simplease.at">office@simplease.at</a></p>
          <p><strong>UID-Nummer:</strong><br/>ATU65823889</p>
          <p>Die Tätigkeit unterliegt der Gewerbeordnung (GewO), die unter <a
            href="http://www.ris.bka.gv.at/bundesrecht">http://www.ris.bka.gv.at/bundesrecht</a> abgerufen werden kann.
          </p>
        </div>
      </div>
    </div>
    <GetInContact />
  </Layout>
);

export default ImpressumPage;